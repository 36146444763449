import {
  AfterViewInit,
  computed,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Signal,
  signal
} from '@angular/core';
import { FormGroupDirective, NgControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { UserPermission } from '../models';
import { UserPermissionsService } from '../services';
import { UserStoreService } from '../stores';

@Directive({
  selector: '[boxEnableIfAllowed]'
})
export class EnableIfAllowedDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() skipControls: string[] = [];
  @Input() skipForm: boolean;
  readonly $disabled: Signal<boolean>;

  private disableSubject = new BehaviorSubject<boolean>(false);
  disabled$ = this.disableSubject.asObservable();

  $actions = signal([]);

  private originalHref: string | null = null;
  private destroyed$ = new Subject();

  constructor(
    private permissionsService: UserPermissionsService,
    private el: ElementRef,
    private renderer: Renderer2,
    private userStore: UserStoreService,
    @Optional() private ngControl: NgControl,
    @Optional() private formDirective: FormGroupDirective,
  ) {

    this.$disabled = computed((
        () => {
          const user = this.userStore.currentS();

          return !this.$actions().some(a => this.permissionsService.isActionAllowedByUser(user, a));
        }),
      { equal: () => false }
    );

    //effect(() => {
    //  if (this.$disabled()) {
    //    this.disable();
    //  } else {
    //    // removed this, so we only initial disable fields, and leave them as is if user have permissions
    //    //this.enable();
    //  }
    //});
  }

  get disabled(): boolean {
    return this.disableSubject.value;
  }

  get isLink(): boolean {
    return this.el.nativeElement?.tagName === 'A';
  }

  @Input() set boxEnableIfAllowed(actions: UserPermission | UserPermission[] | '') {
    this.$actions.set(Array.isArray(actions) ? actions : [ actions ]);
  }

  ngOnInit(): void {
    // todo rework
    // filter only user permissions, skip base user
    this.userStore.current$
      .pipe(
        filter(user => !!user?.permissions),
        take(1),
      )
      .subscribe(user => {
        // only initially disable, with first non-base user and actions
        if (!this.$actions().some(a => this.permissionsService.isActionAllowedByUser(user, a))) {
          this.disable();
        }
      });
  }

  ngAfterViewInit(): void {
    // update actions when form is available
    this.$actions.update(a => a);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  //private enable(): void {
  //  this.disableSubject.next(false);
  //  this.toggleForm('enable');
  //  this.ngControl?.control?.enable();
  //  if (this.isLink) {
  //    this.toggleLink('enable');
  //  }
  //}

  private disable(): void {
    this.disableSubject.next(true);
    this.toggleForm('disable');
    this.ngControl?.control?.disable();
    if (this.isLink) {
      this.toggleLink('disable');
    }
  }

  private toggleForm(state: 'enable' | 'disable'): void {
    if (this.skipForm) {
      return;
    }

    const form = this.formDirective?.form;

    if (!form) {
      return;
    }

    Object.keys(form.controls).forEach(key => {
      // we don't need it, because we only initially disable form controls
      //if (this.skipControls.includes(key) && state === 'enable') {
      //  return;
      //}

      form.get(key)[ state ]({ emitEvent: false });
      form.markAsPristine();
    });


    // save previous states of ignored controls
    //const controlStates = this.skipControls.map(field => {
    //  const control = form.get(field);
    //
    //  return { control, isEnabled: control.enabled };
    //});
    //
    //form[ state ]({ emitEvent: false });
    //
    //controlStates.forEach(item => item.control[ item.isEnabled ? 'enable' : 'disable' ]());

    form.updateValueAndValidity();
  }

  private toggleLink(state: 'enable' | 'disable'): void {
    const element = this.el.nativeElement as HTMLAnchorElement;
    if (state === 'disable') {
      this.originalHref = element.getAttribute('href');
      this.renderer.removeAttribute(element, 'href');
      this.renderer.setStyle(element, 'pointer-events', 'none');
      this.renderer.setStyle(element, 'cursor', 'not-allowed');
      this.renderer.listen(element, 'click', (event) => {
        event.preventDefault();
      });
    } else {
      if (this.originalHref !== null) {
        this.renderer.setAttribute(element, 'href', this.originalHref);
      }
      this.renderer.setStyle(element, 'pointer-events', 'auto');
      this.renderer.setStyle(element, 'cursor', 'pointer');
    }
  }

}
